<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title>Edición del Campo {{campo.nombre}} de {{layout.nombre}}</v-card-title>
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="campo.nombre"
                label="Nombre del Campo"
                :rules="rules.concat(v => (v && v.length <= 100) || 'Máximo 100 caracteres')"
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="campo.alias"
                label="Alias"
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="campo.descripcion"
                label="Descripción"
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="campo.orden"
                label="Orden"
                :counter="3"
                :rules="required.concat(v => (v && v.toString().length <= 3) || 'Máximo 3 caracteres')"
                type="number"
                hide-details="auto" 
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="campo.longitud"
                label="Longitud"
                :counter="4"
                :rules="required.concat(v => (v && v.toString().length <= 3) || 'Máximo 3 caracteres')"
                type="number"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="campo.tipo_campo_id"
                :items="tiposCampo"
                :item-text="'text'"
                :item-value="'value'"
                attach
                label="Tipo de Campo"
                :rules="required"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="campo.valorPorDefecto"
                label="Valor por Defecto"
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-if="campo.tipo_campo_id === 3"
                v-model="campo.formato_fecha_id"
                :items="formatos"
                label="Formato"
                :item-text="'text'"
                :item-value="'value'"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-switch
                v-model="campo.admiteNulidad"
                :label="`Admite Nulidad`"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                v-model="campo.llavePrimaria"
                :label="`Es Llave Primaria`"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                v-model="campo.activo"
                :label="`Estado`"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CamposService from "@/services/campo.service";
import RoleService from "@/services/role.service";
import validationField from "@/util/validationField";
import { mapState } from "vuex";

export default {
  name: "Calidad",

  data() {
    return {
      layout: {
        nombre: undefined
      },
      campo: {
        nombre: undefined,
        alias: undefined,
        descripcion: undefined,
        orden: undefined,
        tipo_campo_id: undefined,
        longitud: undefined,
        formato_fecha_id: undefined,
        valorPorDefecto: undefined,
        admiteNulidad: 1,
        llavePrimaria: 1,
        activo: 1,
      },
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 2) || "Mínimo 2 caracteres",
      ],
      required: [
        (value) => !!value || "Requerido"
      ],
      tiposCampo: [
        { value: 1, text: "Numérico" },
        { value: 2, text: "Texto" },
        { value: 3, text: "Fecha" }
      ],
      formatos: [
        { value: 1, text: "DD/MM/YY" },
        { value: 2, text: "YYYY-MM-DD" },
      ],
      loading: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Definición de variables"))
      this.$router.push("/dashboard");
    
    if (localStorage.getItem("datos_campo")) {
      this.campo = JSON.parse(localStorage.getItem("datos_campo"));
    } 
    if (localStorage.getItem("datos_layout")) {
      this.layout = JSON.parse(localStorage.getItem("datos_layout"));
      console.log(this.layout)
    }

    this.fetchRoles();
  },
  methods: {
    save() {
      this.loadingSave = true;
      if (this.campo.hasOwnProperty("id")) {
        CamposService.editItem(this.layout.id, this.campo).then(
          (response) => {
            this.$router.push({ path: `/cargue-archivos/layouts/campos` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
        
      } else {
        CamposService.newItem(this.layout.id, this.campo).then(
          (response) => {
            this.$router.push({ path: `/cargue-archivos/layouts/campos` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchRoles() {
      this.loading = true;
      RoleService.getItems().then(
        (response) => {
          this.roles = response.data;
          console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true
    },
    goBack() {
      this.$router.push({ path: "/cargue-archivos/layouts/campos" });
    },
  },
};
</script>

<style>
</style>
